.contact-section {
  @apply
    w-full flex flex-col items-center
    lg:flex-row lg:items-center
    lg:py-4
    bg-slate-900 text-slate-50
}

.contact-info {
  @apply
    w-[90%] md:w-[95%] lg:w-1/2
}

.contact-form {
  @apply
    w-[90%] md:w-[95%] lg:w-1/2 lg:p-4 relative
}

.contact-form-dialogs {
  @apply
    absolute top-0 left-0 h-full w-full z-10
    flex items-center justify-center
}

.contact-dialog {
  @apply
    bg-slate-50 text-slate-950
    h-auto w-96 p-4 rounded-xl -mt-24
    flex flex-col
    transition ease-in-out duration-300
}

.dialog-header {
  @apply
    w-full flex items-center h-8 text-xl font-bold p-4
}

.dialog-content {
  @apply
    w-full flex flex-col items-center px-4 text-slate-900
}

.dialog-footer {
  @apply
    w-full flex flex-col items-center justify-center mt-6 mb-2 h-auto
}

.dialog-footer-text {
  @apply
    text-xs text-center font-semibold
}

.dialog-footer-icon {
  @apply
    size-10 sm:size-8 animate-pulse hover:animate-none
    hover:scale-125 mx-1
    hover:cursor-pointer
    transition ease-in-out duration-300
}

.error {
  @apply text-red-700
}

.success {
  @apply text-green-700
}

.loading {
  @apply text-blue-700 flex justify-center
}

.loading-text {
  @apply text-center text-xs
}

.loading-icon {
  @apply size-10 sm:size-8 animate-spin my-4
}