.services {
  @apply
    min-h-[100vh/2] w-screen px-4 py-8
    flex flex-col items-center justify-center
    bg-slate-50 text-slate-900 relative
}

.services-background {
  @apply
    absolute bottom-0 left-0
    h-1/2 w-screen
    bg-slate-900
}

.services-header {
  @apply
    w-[90%] md:w-auto text-4xl font-bold
    md:mb-4
}

.services-content {
  @apply
    w-full lg:w-3/4 flex flex-col md:flex-row
    items-center md:items-start justify-center
    p-4 pb-16 mt-4 z-10
    border-b border-b-slate-300
}

.service {
  @apply
    w-full md:w-1/4 aspect-[3/4.5] p-4 mb-4 mx-2 md:my-0
    flex flex-col items-center
    bg-slate-200 text-slate-950 rounded-xl relative
}

.service-title {
  @apply
    font-bold md:font-extrabold lg:font-bold
    text-xl md:text-sm lg:text-base
}

.service-button-wrapper {
  @apply
    absolute bottom-4 right-4
    w-full flex justify-end items-center
}

.service-button {
  @apply
    w-8 h-8 rounded-full ms-2
    bg-slate-700 text-slate-50
    flex justify-center items-center
    hover:bg-slate-900 hover:scale-125 hover:cursor-pointer
    hover:shadow-sm hover:shadow-slate-900
    transition ease-in-out duration-300
}

.service-more {
  @apply
    font-bold text-xl
}