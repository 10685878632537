.bottom-navbar {
  @apply 
    fixed bottom-0 left-0 w-full h-[4.5rem] z-40
    bg-slate-700 text-slate-50 shadow-lg
    flex items-center
    transition-all duration-300 ease-in-out 
    translate-y-full opacity-0;
}

.bottom-navbar.visible {
  @apply
    translate-y-0 opacity-100
}

.bottom-navbar-button {
  @apply
    flex flex-col items-center justify-center w-1/4
    transition-colors duration-200 ease-in-out
}

.enabled:active,
.enabled:focus {
  @apply
    text-gray-600
}

.bottom-navbar-icon {
  @apply
    text-2xl mb-1
}

.bottom-navbar-text {
  @apply
    text-xs
}

.disabled {
  @apply
    opacity-50
}

.language-button {
  @apply
    flex items-center justify-center
}

.col-button {
  @apply
    flex flex-col items-center justify-center
}