.account-modal-backdrop {
  @apply
    fixed inset-0 bg-black bg-opacity-70
    flex items-center justify-center z-50
    hover:cursor-pointer
    overflow-hidden
}

.account-modal {
  @apply
    flex flex-col items-center justify-center
    w-[330px] h-[160px] rounded-lg shadow-lg p-4
    bg-slate-50 text-slate-900
    hover:cursor-default
}

.account-login-button {
  @apply
    h-12 w-11/12 px-4 py-2 my-2 rounded-full
    flex justify-center items-center font-semibold
    bg-slate-900 text-slate-50
    transition ease-in-out duration-300
}

.account-login-button:focus,
.account-login-button:active {
  @apply
    bg-slate-800 text-slate-100 scale-110
}

.account-register-button {
  @apply
    h-12 w-11/12 px-4 py-2 my-2 rounded-full
    flex justify-center items-center font-semibold
    bg-yellow-500 text-slate-50
    transition ease-in-out duration-300
}

.account-register-button:focus,
.account-register-button:active {
  @apply
    bg-yellow-500 text-slate-100 scale-110
}