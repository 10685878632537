/* Main Container */
.courses-schedule-container {
  @apply w-full h-full flex flex-col p-4;
}

/* Section Headers */
.courses-schedule-header {
  @apply w-full text-sm font-bold mb-2;
}

/* Grid Layout for Courses & Availability */
.courses-grid, .availability-grid {
  @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2;
}

/* Other Course Container - Ensure Horizontal Alignment */
.other-course-container {
  @apply flex items-center space-x-1; /* Reduce space */
}

/* Reduce field width to prevent excessive gap */
.other-course-field {
  @apply flex-grow w-40; /* Adjust width as needed */
}

/* Reduce Checkbox Margin to bring it closer */
.other-course-checkbox {
  @apply ml-1; /* Adjust margin-left */
}

/* Error Message */
.courses-schedule-error {
  @apply text-xs text-red-500 mt-2;
}