.admin-modal-backdrop {
  @apply
    fixed inset-0 bg-black bg-opacity-70
    flex items-center justify-center z-50
    hover:cursor-pointer
    overflow-hidden
}

.admin-modal {
  @apply
    w-3/4 h-3/4 bg-white rounded-lg shadow-lg p-4
    hover:cursor-default
}
