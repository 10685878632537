.contact-text {
  @apply
    h-full w-full p-4 lg:px-24
    flex flex-col justify-center items-center
}

.contact-text-header {
  @apply
    w-full flex justify-start
    text-3xl font-extrabold
    mb-4
}

.contact-text-content {
  @apply
    w-full flex justify-start
    font-light
}