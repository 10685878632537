/* Modal Backdrop */
.new-session-modal-backdrop {
  @apply fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 hover:cursor-pointer overflow-hidden;
}

/* Modal Container */
.new-session-modal {
  @apply w-[70%] h-auto lg:w-[50%] lg:h-auto rounded-lg shadow-lg px-6 py-8 md:p-8 bg-slate-50 text-slate-900 flex flex-col hover:cursor-default relative;
}

/* Logo Placement */
.new-session-modal-logo {
  @apply w-full absolute -top-10 left-0 flex justify-center items-center;
}

.modal-logo {
  @apply h-20 w-20 pt-1 shadow-sm shadow-slate-400 bg-slate-50 rounded-full;
}

/* Modal Header */
.new-session-modal-header {
  @apply font-bold text-2xl text-center mt-12;
}

/* Time & Duration - On separate rows for mobile, in the same row for larger screens */
.time-duration-container {
  @apply flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0;
}

.modal-input .MuiInputBase-root {
  height: 56px !important;
}

/* Ensure spacing in the modal */
.new-session-modal-body {
  @apply flex flex-col space-y-4 mt-4;
}

/* Form Fields */
.modal-input {
  @apply w-full;
}

.modal-input .MuiInputBase-root {
  height: 56px !important; /* Match standard MUI TextField height */
}

/* Modal Footer */
.new-session-modal-footer {
  @apply mt-6 flex justify-center;
}

/* Button */
.new-session-modal-button {
  @apply bg-blue-600 text-white
  font-bold py-3 px-6 rounded-lg text-center md:w-1/2
  hover:bg-blue-800 transition-all duration-300
  hover:cursor-pointer hover:scale-110;
}

.new-session-modal-button:focus
.new-session-modal-button:active {
  @apply hover:scale-110;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .new-session-modal {
    @apply w-[85%] h-[90%] px-4 py-2 overflow-y-scroll;
  }

  .modal-logo {
    @apply h-16 w-16 hidden;
  }

  .new-session-modal-header {
    @apply text-xl;
  }

  .new-session-modal-body {
    @apply mt-2;
  }

  .new-session-modal-button {
    @apply pt-2;
  }
}

/* Error Text Styling */
.error-text {
  color: #d32f2f; /* MUI default error color */
  font-size: 0.75rem;
  margin-top: 4px;
  font-weight: 500;
}