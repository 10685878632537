/* 📌 Main Container */
.register-stepper-container {
  @apply w-full h-auto flex flex-col items-center relative overflow-y-auto;
}

/* 📌 Stepper Content */
.register-stepper-content {
  @apply flex-grow flex justify-center items-center w-full lg:w-[90%] p-4;
}

/* 📌 Stepper Navigation Buttons */
.register-stepper-buttons {
  @apply flex justify-between items-center w-full py-4 px-8;
}

/* 📌 Button Style */
.register-stepper-button {
  @apply bg-gray-200 text-gray-700 rounded-full p-3 shadow-md transition-all duration-300;
}

.register-stepper-button:hover {
  @apply bg-gray-300;
}

/* 📌 Stepper */
.register-stepper {
  @apply w-full flex items-center justify-center mt-4;
}

/* 📌 Stepper Icon */
.step-icon {
  @apply flex items-center justify-center w-8 h-8 rounded-full bg-gray-300 text-gray-700 font-bold;
}

.step-icon.active {
  @apply bg-blue-500 text-white;
}

.step-icon.completed {
  @apply bg-green-500 text-white;
}

/* 📌 Confirm Button */
.register-stepper-confirm-button {
  @apply bg-green-700 text-white font-bold flex items-center justify-center p-4 rounded-full mt-6 h-12 w-12;
  @apply hover:bg-green-500 transition ease-in-out duration-300 hover:scale-105;
}