.navbar {
  @apply 
    h-16 w-screen bg-slate-50 text-slate-900
    fixed top-0 left-0
    font-semibold
    grid grid-cols-12 z-50
}

.navbar-section {
  @apply flex mx-2 items-center
}

.navbar-logo {
  @apply
    col-span-1 flex justify-center items-center align-middle
}

.logo {
  @apply
    h-14 w-14
}

.navbar-links {
  @apply
    col-start-2 col-span-5 flex justify-start
}

.navbar-link {
  @apply
    mx-2
    hover:text-slate-400 hover:cursor-pointer hover:scale-110
    transition ease-in-out duration-300
}

.navbar-user {
  @apply
    col-span-5 flex justify-end
}

.navbar-button {
  @apply
    px-4 py-2 w-28 text-center
    hover:cursor-pointer hover:scale-110
    transition ease-in-out duration-300
}

.navbar-user-register {
  @apply 
    px-2  
  hover:text-slate-400
}

.navbar-user-login {
  @apply
    bg-slate-700 text-slate-100 rounded-full
    hover:bg-slate-900
}

.navbar-user-schedule, .navbar-user-logout, .navbar-admin, .navbar-user-logout-en,
.navbar-admin-en {
  @apply
  bg-slate-700 text-slate-100 rounded-full
  hover:bg-slate-900 hover:scale-110
  w-40 mx-2 flex justify-evenly items-center
}

.navbar-admin {
  @apply
    w-44
}

.navbar-user-schedule, .navbar-user-logout-en, .navbar-admin-en {
  @apply
    w-36
}

.navbar-lang-section {
  @apply
    group-hover/lang:cursor-pointer
    group-hover/lang:text-slate-400
    col-span-1 flex justify-start
    transition ease-in-out duration-300
    group-hover/lang:scale-110
}

.navbar-lang {
  @apply
    text-slate-900 font-bold
    group-hover/lang:cursor-pointer
    group-hover/lang:text-slate-400
    transition ease-in-out duration-300
    group-hover/lang:scale-110
}

.navbar-globe {
  @apply
    size-8 text-slate-900
    group-hover/lang:cursor-pointer
    group-hover/lang:text-slate-400
    transition ease-in-out duration-300
    group-hover/lang:scale-110
}

.navbar-curent-icon {
  @apply
    size-5 text-slate-50
    hover:cursor-pointer
}