/* 📌 Main Container */
.child-info-container {
  @apply flex flex-col p-4 bg-gray-50 rounded-lg shadow-sm mt-4 w-full;
}

/* 📌 Header with child index and delete button */
.child-header {
  @apply flex justify-between items-center mb-2;
}

.child-index {
  @apply text-lg font-semibold;
}

/* 📌 Remove Button */
.remove-child-button {
  @apply bg-red-600 text-white rounded-full w-8 h-8 flex items-center justify-center;
  @apply hover:bg-red-700 transition duration-300;
}

/* 📌 Responsive Input Fields */
.child-fields {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4;
}

/* 📌 Date Picker Styling */
.date-picker {
  @apply w-full;
}

.date-picker-underline:before {
  border-bottom: 2px solid #1e293b; /* slate-900 */
}

.date-picker-underline:after {
  border-bottom: 2px solid #334155; /* slate-700 */
}

.date-picker-label-focused {
  color: #0f172a !important; /* slate-900 */
}

.date-picker-error,  
.date-picker-label-error {
  color: #e53e3e !important; /* red-600 */
}

/* 📌 Responsive Adjustments for Mobile */
@media (max-width: 768px) {
  .child-fields {
    @apply grid-cols-1;
  }

  .remove-child-button {
    @apply w-10 h-10; /* Larger tap target */
  }
}