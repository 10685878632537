.login-modal-backdrop {
  @apply
    fixed inset-0 bg-black bg-opacity-70
    flex items-center justify-center z-50
    hover:cursor-pointer
    overflow-hidden
}

.login-modal {
  @apply
    w-[90%] md:w-[420px] h-[340px] rounded-lg shadow-lg p-8
    bg-slate-50 text-slate-900
    flex flex-col
    hover:cursor-default relative
}

.login-modal-logo {
  @apply
    w-full absolute -top-10 left-0
    flex justify-center items-center
}

.modal-logo {
  @apply
    h-20 w-20 pt-1 shadow-sm shadow-slate-400
    bg-slate-50 rounded-full
}

.login-modal-header {
  @apply
    font-bold text-2xl text-center mt-4
}

.login-modal-body {
  @apply
    flex flex-col -mt-2
}

.login-modal-footer {
  @apply
    absolute bottom-8 right-0 px-8
    flex justify-end w-full
}

.login-modal-button {
  @apply
    bg-slate-700 text-slate-100 font-bold
    flex items-center py-4 px-8 rounded-full w-full justify-center
    transition ease-in-out duration-300
    hover:bg-slate-900 hover:cursor-pointer scale-90 hover:scale-100
}