.mobile-schedule-session-details {
  @apply w-[95%] bg-slate-300 my-2 rounded-xl hover:bg-slate-200 
         hover:scale-105 transition ease-in-out duration-300 
         flex items-center p-4;
}

/* Grid layout for desktop */
.mobile-schedule-session-container {
  @apply w-full grid grid-cols-12;
}

/* Status-based background color (mobile) */
.mobile-schedule-session-pill {
  @apply w-full p-3 rounded-lg shadow-md text-white;
}

/* Status-specific colors */
.session-confirmed {
  @apply bg-green-600;
}

.session-canceled {
  @apply bg-red-600;
}

.session-scheduled {
  @apply bg-yellow-500;
}

.session-unknown {
  @apply bg-gray-600;
}

/* Course, Date, Time (Desktop) */
.mobile-schedule-session-course,
.mobile-schedule-session-date,
.mobile-schedule-session-time {
  @apply h-full col-span-1 flex justify-center items-center text-xs 
         text-wrap break-words text-center;
}

/* Location, Teacher/Student (Desktop) */
.mobile-schedule-session-location,
.mobile-schedule-session-teacher,
.mobile-schedule-session-student {
  @apply h-full col-span-3 flex justify-center items-center text-xs 
         text-wrap break-words text-center;
}

/* Action Buttons */
.mobile-schedule-session-options {
  @apply h-full col-span-2 flex justify-end items-center text-xs 
         text-wrap break-words text-center;
}

.mobile-schedule-session-option-icon {
  @apply hover:scale-125 mx-1 hover:cursor-pointer transition 
         ease-in-out duration-300;
}

/* 🌟 Mobile Layout: Multiple Rows & Color-Based Pills 🌟 */
/* 🌟 Mobile-Specific Adjustments 🌟 */
@media (max-width: 768px) {
  .mobile-schedule-session-container {
    @apply flex flex-col w-full p-3 text-sm gap-2;
  }

  /* Status-based background color for the entire pill */
  .mobile-schedule-session-pill {
    @apply flex flex-col p-4 rounded-lg shadow-md text-white;
  }

  /* First Row: Date, Time, Location */
  .mobile-schedule-session-row-1 {
    @apply flex justify-between items-center text-xs;
  }

  /* Second Row: Course & Teacher/Student */
  .mobile-schedule-session-row-2 {
    @apply flex justify-between items-center text-sm font-medium;
  }

  /* Third Row: Larger Action Buttons */
  .mobile-schedule-session-row-3 {
    @apply flex justify-center items-center gap-4 mt-3;
  }

  .mobile-schedule-session-option {
    @apply flex justify-center items-center w-12 h-12 rounded-full bg-white shadow-lg 
           hover:scale-110 transition-all duration-300;
  }

  .mobile-schedule-session-option-icon {
    @apply text-xl; /* Bigger icon size */
  }

  /* Status-Specific Colors */
  .session-confirm {
    @apply text-green-700;
  }

  .session-cancel {
    @apply text-red-700;
  }

  .session-delete {
    @apply text-gray-700;
  }
}

.mobile-schedule-session-status {
  @apply text-center mt-2 text-sm font-semibold;
}