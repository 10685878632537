/* Container */
.agreement-container {
  @apply w-full h-full flex flex-col p-4;
}

/* Header */
.agreement-header {
  @apply w-full text-sm font-bold ms-4 mt-2 mb-2 text-center md:text-left;
}

/* Responsive Row */
.agreement-row {
  @apply w-full flex flex-col md:flex-row mb-4 space-y-2 md:space-y-0 md:space-x-4;
}

/* List Items */
.agreement-list {
  @apply list-disc list-inside ms-4 md:ms-6 text-sm;
}

/* Confirmation Section */
.agreement-confirmation {
  @apply w-full flex flex-col md:flex-row items-center md:items-start md:space-x-4;
}

/* Checkbox Container */
.agreement-checkbox {
  @apply flex items-center w-full md:w-1/6 pt-[0.7rem];
}

/* Input Fields - Mobile Stack, Desktop Side-by-Side */
.agreement-input {
  @apply w-full md:w-1/3;
}

/* Adjust Password Fields for Better Layout */
.agreement-password {
  @apply w-full md:w-1/4;
}