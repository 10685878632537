.mobile-child-info-container {
  @apply flex flex-col p-4 bg-gray-50 rounded-lg shadow-sm mt-4 w-full;
}

/* Header with child index and delete button */
.mobile-child-header {
  @apply flex justify-between items-center mb-2;
}

.mobile-child-index {
  @apply text-lg font-semibold;
}

.remove-mobile-child-button {
  @apply bg-red-600 text-white rounded-full w-8 h-8 flex items-center justify-center 
         hover:bg-red-700 transition duration-300;
}

/* Responsive Fields: Single Column on Mobile, Grid on Larger Screens */
.mobile-child-fields {
  @apply grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4;
}

.mobile-date-picker {
  @apply w-full;
}

.mobile-date-picker-underline:before {
  border-bottom: 2px solid #1e293b; /* slate-900 */
}

.mobile-date-picker-underline:after {
  border-bottom: 2px solid #334155; /* slate-700 */
}

.mobile-date-picker-label-focused {
  color: #0f172a !important; /* slate-900 */
}

.mobile-date-picker-error,  .mobile-date-picker-label-error {
  color: #e53e3e !important; /* red-600 */
}