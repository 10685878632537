/* 📌 Main Container */
.parental-identification-container {
  @apply w-full h-full flex flex-col p-4 pb-12 overflow-y-auto;
}

/* 📌 Section Headers */
.parental-identification-header,
.section-header {
  @apply text-lg font-bold text-center mt-4;
}

/* 📌 Form Inputs (Responsive Grid) */
.parental-identification-form {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4;
}

/* 📌 Radio Group (for selecting 'self' or 'children') */
.radio-group {
  @apply flex justify-center mt-2;
}

/* 📌 Child Information Container (Scrollable if Many Children) */
.parental-child-container {
  @apply flex flex-col gap-4 mt-4 overflow-y-auto max-h-[50vh] p-4 border rounded-lg bg-gray-100;
}

/* 📌 Button to Add More Children */
.parental-identification-button {
  @apply bg-blue-600 text-white font-bold py-3 px-6 rounded-md text-center mt-4 w-full;
  @apply transition duration-300 ease-in-out hover:bg-blue-700 hover:scale-105;
}

/* 📌 Remove Child Button */
.remove-child-button {
  @apply bg-red-600 text-white rounded-full w-8 h-8 flex items-center justify-center;
  @apply hover:bg-red-700 transition duration-300;
}

/* 📌 Date Picker Styling */
.date-picker {
  @apply w-full;
}

/* 📌 Date Picker Focus & Error States */
.date-picker-underline:before {
  border-bottom: 2px solid #1e293b; /* slate-900 */
}
.date-picker-underline:after {
  border-bottom: 2px solid #334155; /* slate-700 */
}
.date-picker-label-focused {
  color: #0f172a !important; /* slate-900 */
}
.date-picker-error,  
.date-picker-label-error {
  color: #e53e3e !important; /* red-600 */
}

/* 📌 Responsive Adjustments for Mobile */
@media (max-width: 768px) {
  .parental-identification-form {
    @apply grid-cols-1;
  }

  .parental-child-container {
    max-height: 60vh; /* Allow more scrolling room on mobile */
  }

  .parental-identification-button {
    @apply text-sm py-2 px-4;
  }
}