.schedule-modal-backdrop {
  @apply fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 hover:cursor-pointer overflow-hidden;
}

.schedule-modal {
  @apply w-[90%] h-[80%] lg:min-w-[1024px] lg:min-h-[640px] lg:w-[50%] lg:h-[75%] lg:max-h-[75%]
         rounded-lg shadow-lg px-4 py-8 md:p-8 bg-slate-50 text-slate-900 flex flex-col hover:cursor-default relative;
}

.schedule-modal-logo {
  @apply w-full absolute -top-10 left-0 flex justify-center items-center;
}

.modal-logo {
  @apply h-20 w-20 pt-1 shadow-sm shadow-slate-400 bg-slate-50 rounded-full;
}

.schedule-modal-header {
  @apply flex justify-between items-center w-full pb-4 border-b border-gray-300;
}

.schedule-modal-header-title {
  @apply font-bold text-2xl text-center mt-4 w-1/3;
}

/* Mobile Layout */
.schedule-modal-body.mobile {
  @apply w-full h-[85%] overflow-y-auto overflow-x-hidden flex flex-col items-center;
}

/* Web Layout (Grid with 2 or 3 sessions per row) */
.schedule-modal-body.web {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* 2 or 3 per row */
  gap: 12px;
  width: 100%;
  height: 85%;
  overflow-y: auto;
  padding: 8px;
}

/* Active Filter Button */
.filter-button.active {
  @apply bg-blue-500 text-white font-semibold;
}

/* Modal Action Button */
.schedule-modal-option {
  @apply flex justify-center items-center w-12 h-12 rounded-full bg-blue-700 shadow-lg
         ring ring-slate-400 ring-opacity-50 hover:bg-blue-500
         hover:ring-offset-2 text-white hover:cursor-pointer
         hover:scale-110 transition-all duration-300;
}

.schedule-modal-option-icon {
  @apply text-xl; /* Bigger icon size */
}

/* Adjust filter container */
.schedule-filter-div {
  @apply flex lg:grid lg:grid-cols-4 mt-2 mb-2 w-1/3;
}

/* Base filter button styling */
.filter-button {
  @apply flex items-center justify-center px-4 py-2 text-sm font-semibold 
         rounded-md cursor-pointer transition-all duration-300 shadow-sm
         col-span-1 aspect-square w-8 mx-1 my-1 lg:w-12;
}

/* Individual filter colors */
.filter-button-all {
  @apply bg-blue-500 text-white hover:bg-blue-700;
}

.filter-button-confirmed {
  @apply bg-green-500 text-white hover:bg-green-700;
}

.filter-button-scheduled {
  @apply bg-yellow-500 text-white hover:bg-yellow-700;
}

.filter-button-canceled {
  @apply bg-red-500 text-white hover:bg-red-700;
}

/* Active state (selected filter) */
.filter-button.active {
  @apply ring-2 ring-offset-2 ring-gray-300;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .schedule-filter-div {
    justify-content: center;
    flex-wrap: wrap;
    gap: 4px;
  }

  .filter-button {
    padding: 6px 10px;
    font-size: 0.8rem;
  }
}