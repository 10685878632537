.contact-row {
  @apply
    flex flex-col md:flex-row md:mb-4
}

.contact-row-item-1 {
  @apply
    w-full pb-4 md:w-1/2 md:pe-4 md:pb-0
}

.contact-row-item-2 {
  @apply
    w-full pb-4 md:w-1/2 md:ps-4 md:pb-0
}

.contact-form-button {
  @apply
    h-12 w-full px-4 py-2 my-2
    flex justify-center items-center font-semibold
    border border-slate-50 rounded-md
    hover:bg-slate-50 hover:text-black hover:cursor-pointer
    transition ease-in-out duration-300
}