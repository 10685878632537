.landing {
  @apply
    h-full w-full px-4 lg:pt-4
    flex flex-col items-center justify-center
    bg-slate-50 text-slate-900 relative;
}

.carousel-container {
  @apply
    h-full w-screen
    lg:h-4/5 lg:w-4/5 lg:rounded-xl
    bg-black shadow-lg
    flex items-center justify-center;
}

.carousel {
  @apply
    relative w-full h-full overflow-hidden;
}

.carousel-image {
  @apply
    absolute top-0 left-0 w-full h-full object-cover opacity-0 transition-opacity duration-[2000ms];
}

.carousel-image.visible {
  @apply opacity-100;
}

.carousel-text {
  @apply
    p-4 hidden lg:flex items-center justify-center
    text-3xl;
}

.responsive-cover {
  @apply
    w-full h-full z-10
    bg-slate-950 opacity-25
    absolute top-0 left-0
    flex lg:hidden;
}

.responsive-title {
  @apply
    w-full h-32 z-20 text-slate-50
    text-4xl font-semibold
    flex lg:hidden items-center justify-center
    absolute bottom-[160px] md:bottom-[160px] left-0;
}

.responsive-slider {
  @apply
    w-full z-20 text-slate-50
    flex lg:hidden items-center justify-center ps-7
    absolute bottom-[96px] md:bottom-[96px] left-0;
}

.slider-icon {
  @apply text-6xl animate-pulse;
}

.landing-logo {
  @apply h-full;
}