.services-modal-backdrop {
  @apply
    fixed inset-0 bg-black bg-opacity-70
    flex items-center justify-center z-50
    hover:cursor-pointer
    overflow-hidden
}

.services-modal {
  @apply
    w-[90%] lg:min-w-[800px] lg:w-2/5 h-auto max-h-[90%]
    rounded-lg shadow-lg p-4
    overflow-y-auto
    bg-slate-50 text-slate-900
    hover:cursor-default
}