.about {
  @apply
    w-screen p-4
    flex flex-col items-center justify-center
    bg-slate-50 text-slate-900
}

.about-header {
  @apply
    w-[90%] md:w-auto text-4xl font-bold
    mb-4
}

.about-header-body {
  @apply
    text-xl md:text-center
    w-[90%] md:w-4/5 lg:w-3/5 xl:w-2/3 mb-4 pb-4
    border-b border-b-slate-300
}

.about-subheader {
  @apply
  text-slate-500 text-xl lg:text-base
    w-[90%] md:w-4/5 lg:w-3/5 xl:w-2/3 lg:mb-4
}

.about-teachers {
  @apply
    w-full flex flex-col items-center p-4
}

.about-teachers-header {
  @apply
    text-3xl font-semibold text-slate-500 mt-4
    w-full md:w-auto
}

.about-teachers-container {
  @apply
    w-full lg:w-3/4 p-4 rounded-xl mt-4
    flex flex-col md:flex-row justify-center
    bg-slate-900 text-slate-50
}

.about-teacher {
  @apply
    w-full md:w-1/4 px-4 py-8 md:py-4
    flex flex-col items-center
    border-b border-b-slate-300 md:border-b-0
}

.about-teacher-image {
  @apply
    w-4/5 aspect-square object-cover rounded-full my-4
    border border-slate-500
}

.about-teacher-content {
  @apply
    text-center text-lg md:text-sm
}

.about-subjects {
  @apply
    w-full lg:w-3/4 flex flex-col items-center p-4
}

.about-subjects-header {
  @apply
    w-full md:w-auto text-3xl font-semibold text-slate-500
}

.about-subjects-container {
  @apply
    w-full lg:w-3/4 p-4 rounded-xl mt-4
    flex flex-col md:flex-row justify-center
    bg-slate-900 text-slate-50
}

.about-subject {
  @apply
    h-24 md:h-auto w-full md:w-1/5
    px-4 my-2 md:my-0
    flex flex-col items-center justify-center
}

.with-divider {
  @apply
    border-b border-b-slate-300
    md:border-b-0
    md:border-r md:border-r-slate-300
}

.about-subject-content {
  @apply
    text-center text-lg md:text-sm
    flex items-center justify-center h-full
}