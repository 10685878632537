.footer {
  @apply
    h-16 w-screen pt-4 pb-8 p-4 
    bg-slate-900 text-slate-50
    flex-row justify-evenly items-center
    font-semibold text-base
    hidden lg:flex
}

.footer-section {
  @apply flex mx-2 items-center
}

.footer-link {
  @apply
    mx-1 my-2 lg:my-0
    hover:text-slate-400 hover:cursor-pointer
    transition ease-in-out duration-300
}

.footer-numbers {
  @apply
    flex flex-col lg:flex-row
}

.footer-number {
  @apply
    flex items-center align-middle
    mx-4
}

.footer-responsive {
  @apply
    flex flex-col lg:hidden pb-40
}

.footer-responsive-section {
  @apply
    w-full flex items-center
    flex-wrap sm:flex-nowrap
}

.spread {
  @apply
    justify-evenly sm:justify-center
}

.footer-responsive-link {
  @apply
    sm:mx-4
}

.footer-responsive-numbers {
  @apply
    w-full sm:w-1/2 sm:h-auto h-36
    flex flex-col sm:flex-row
    justify-center sm:justify-evenly
}

.footer-responsive-number {
  @apply
    flex justify-center items-center
}