.register-modal-backdrop {
  @apply
    fixed inset-0 bg-black bg-opacity-70
    flex items-center justify-center z-50
    hover:cursor-pointer
    overflow-hidden
}

.register-modal {
  @apply
    w-[90%] h-[80%]
    lg:min-w-[1024px] lg:min-h-[640px] lg:w-[50%] lg:h-[75%] lg:max-h-[75%]
    rounded-lg shadow-lg px-4 py-8 md:p-8
    bg-slate-50 text-slate-900
    flex flex-col
    hover:cursor-default relative
}

.register-modal-logo {
  @apply
    w-full absolute -top-10 left-0
    flex justify-center items-center
}

.modal-logo {
  @apply
    h-20 w-20 pt-1 shadow-sm shadow-slate-400
    bg-slate-50 rounded-full
}

.register-modal-header {
  @apply
    font-bold text-2xl text-center my-4 md:mt-4
}

.register-modal-body {
  @apply
    flex flex-col -mt-2 lg:mt-4 px-4 py-0 lg:py-4
    w-full h-[95%] lg:h-[90%] mx-auto
}

.register-modal-footer {
  @apply
    absolute bottom-8 right-0 px-8
    flex justify-end w-full
}