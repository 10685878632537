.service-modal {
  @apply
    p-4 flex flex-col justify-between h-full
}

.service-modal-title {
  @apply
    font-bold text-xl mb-4
}

.service-modal-footer {
  @apply
    flex justify-end mt-12 me-4
}

.register-button {
  @apply
    bg-slate-700 text-slate-100 font-bold
    flex items-center py-4 px-8 rounded-full
    transition ease-in-out duration-300
    hover:bg-slate-900 hover:cursor-pointer hover:scale-110
}

.register-icon {
  @apply
    text-2xl ms-4
}